export const customRules = {
  install(Vue) {
    Vue.prototype.$customRules = {
      name: [
        (v) => !!v || "Campo Obrigatório",
        (v) => {
          if (!v) {
            return "Campo Obrigatório!";
          }
          const trimmedValue = v.trim();
          return (
            /^[a-zA-ZÀ-ÖØ-öø-ÿ]+(?: [a-zA-ZÀ-ÖØ-öø-ÿ]+)*$/.test(trimmedValue) ||
            "O nome deve conter apenas letras"
          );
        },
        (v) => {
          if (!v) {
            return "Campo Obrigatório!";
          }
          const trimmedValue = v.trim();
          return (
            (trimmedValue.length >= 6 &&
              trimmedValue.split(" ").every((word) => word.length >= 2)) ||
            "Digite o nome completo sem abreviações!"
          );
        },
      ],
    };
  },
};
