import Vue from "vue";
import Vuex from "vuex";
//then you use Vuex
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        visibility: 1,
        user: null,
        drawer: false,
        loadingRoute: false,
        mobile: false,
        accessDenied: false,
        condominium: null,
        condominiumId: null,
        whitelabel: null,
        list_mode: 1,
        path: null,
        reloadInstructions: [],
        notificationsReceivedByWebsocket: [],
        stopNotificationsReceivedByWebsocket: false,
        contactsReceivedByWebsocket: [],
    },
    getters: {
        selectedCondominiumStoreId: (state) => {
            if (!state.user) return null;
            if (
                [
                    Vue.prototype.$userLevels.LEVEL_ADMIN_GENERAL,
                    Vue.prototype.$userLevels.LEVEL_ADMIN_WHITELABEL,
                ].includes(state.user?.level)
            ) {
                return state?.condominiumId;
            }
            return state.user?.condominium_id;
        }
    },
    mutations: {
        setVisibility: (state, data) => {
            state.visibility = data;
        },
        setUser: (state, data) => {
            state.user = data;
            if (![
                Vue.prototype.$userLevels.LEVEL_ADMIN_GENERAL,
            ].includes(state.user?.level)) {
                /** Se não for admin lvl 1, customizar aparencia do sistema */
                if ([
                    Vue.prototype.$userLevels.LEVEL_ADMIN_WHITELABEL,
                ].includes(state.user?.level)) {
                    /** Se for Admin Whitelabel, pegar o parâmetro direto em user */
                    state.whitelabel = state.user?.whitelabel;
                } else {
                    /** Caso contrário, pegar o whitelabel dentro de condominium dentro de user */
                    state.whitelabel = state.user?.condominium?.whitelabel;
                }
            }

            /** Salvando condomínio atralado ao User */
            if ([
                Vue.prototype.$userLevels.LEVEL_ADMIN_CONDOMINIUM,
                Vue.prototype.$userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
            ].includes(state.user?.level)) {
                /**Se não for admin lvl 1||2, provavelmente têm um condomínio atrelado */
                state.condominium = state?.user?.condominium;
            }
        },
        setAccessDenied: (state, data) => {
            state.accessDenied = data;
        },
        setDrawer: (state, data) => {
            state.drawer = data;
        },
        setLoadingRoute: (state, bool) => {
            state.loadingRoute = bool;
        },
        setMobile: (state, bool) => {
            state.mobile = bool;
        },
        setCondominiumId: (state, data) => {
            state.condominiumId = data;
            if (data) {
                if (data != state?.condominium?.id) {
                    state.condominium = null;
                    if (state.user?.level == Vue.prototype.$userLevels.LEVEL_ADMIN_GENERAL) {
                        state.whitelabel = null;
                    }
                }
            } else {
                state.condominium = null;
                if (state.user?.level == Vue.prototype.$userLevels.LEVEL_ADMIN_GENERAL) {
                    state.whitelabel = null;
                }
            }

        },
        setCondominium: (state, data) => {
            state.condominium = data;
            if (data?.whitelabel) {
                state.whitelabel = data?.whitelabel;
            } else {
                if (state.user?.level == Vue.prototype.$userLevels.LEVEL_ADMIN_GENERAL) {
                    state.whitelabel = null;
                }
            }
        },
        setListMode: (state, data) => {
            state.list_mode = data;
        },
        setPath: (state, data) => {
            state.path = data;
        },
        setReloadInstructions: (state, data) => {
            let instructions = [...state.reloadInstructions];
            instructions.push(data);
            instructions = [...new Set(instructions)];
            state.reloadInstructions = instructions;
        },
        addNotificationReceivedByWebsocket: (state, data) => {
            if (data) {
                state.notificationsReceivedByWebsocket.push(data);
            }
        },
        clearNotificationsReceivedByWebsocket: (state) => {
            state.notificationsReceivedByWebsocket = [];
        },
        stopNotificationsReceivedByWebsocket: (state) => {
            state.stopNotificationsReceivedByWebsocket = true;
        },
        addContactReceivedBySocket: (state, data) => {
            if (data) {
                let index = state.contactsReceivedByWebsocket.findIndex(
                    (i) => i.id == data.id
                );
                if (index == -1) {
                    state.contactsReceivedByWebsocket.push(data);
                }
            }
        },
        removeContactFromReceivedByWebsocket: (state, id) => {
            let index = state.contactsReceivedByWebsocket.findIndex(
                (i) => i.id == id
            );
            if (index != -1) {
                state.contactsReceivedByWebsocket.splice(index, 1);
            }
        },
        clearContactsReceivedByWebsocket: (state) => {
            state.contactsReceivedByWebsocket = [];
        },
    },
});
