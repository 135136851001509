var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.accessDenied ? _c('v-alert', {
    staticStyle: {
      "z-index": "99999999"
    },
    attrs: {
      "dismissible": "",
      "type": "error",
      "prominent": ""
    }
  }, [_vm._v("Você não têm permissão para acessar esta página.")]) : _vm._e(), _vm.startListen ? _c('data-listener') : _vm._e(), !_vm.isFillDataRoute && !_vm.isCameraViewClientPage ? _c('v-app-bar', {
    attrs: {
      "app": "",
      "color": _vm.whitelabel ? _vm.whitelabel.main_color : '#f5f5f5',
      "dark": _vm.whitelabel ? _vm.whitelabel.dark == true : false,
      "light": _vm.whitelabel ? !_vm.whitelabel.dark == true : false
    }
  }, [_vm.isMobile ? _c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$store.commit('setDrawer', !_vm.$store.state.drawer);
      }
    }
  }) : _vm._e(), _c('CondominiumSelector', {
    attrs: {
      "props": {
        dense: true,
        hideDetails: true,
        width: '175px',
        maxWidth: '35vw'
      }
    }
  }), _vm.user ? _c('v-spacer') : _vm._e(), _c('softphone-component'), _vm.user ? _c('span', [_vm._v("Usuário: "), _c('strong', [_vm._v(_vm._s(_vm.user.name))]), _c('SpeakWithEntryControl'), _c('SecurityGates'), _c('NotificationsComponent', {
    ref: "notifications",
    staticClass: "ml-2"
  })], 1) : _vm._e()], 1) : _vm._e(), !_vm.isLoginRoute && !_vm.isFillDataRoute ? _c('navigation-drawer') : _vm._e(), _c('v-main', [_c('v-overlay', {
    attrs: {
      "value": _vm.loadingRoute
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-2"
  }, [_vm._v("Carregando")])])], 1), _c('router-view'), _c('notifications', {
    attrs: {
      "group": "app"
    }
  }), _c('div', {
    class: _vm.hasNotifications ? "content__notifications scrollable" : "content__notifications"
  }, [_vm.hasNotifications ? _c('div', {
    staticClass: "btn__clear__section mt-2"
  }, [_c('v-btn', {
    staticClass: "rounded-pill",
    attrs: {
      "dark": "",
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.closeAllNotifications();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-notification-clear-all")]), _vm._v(" Limpar Notificações ")], 1)], 1) : _vm._e(), _vm.hasNotifications ? _c('div', {
    staticClass: "btn__clear__section mt-2"
  }, [_c('v-btn', {
    staticClass: "rounded-pill",
    attrs: {
      "dark": "",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.stopAllNotifications();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" Parar Notificações ")], 1)], 1) : _vm._e(), _c('notifications', {
    attrs: {
      "group": "custom",
      "position": "top left"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        var _props$item, _props$item$data;
        return [_c('div', {
          staticClass: "notification__content"
        }, [props.item.data.img_notification ? _c('div', {
          staticClass: "img__section"
        }, [_c('v-spacer'), _c('v-responsive', {
          attrs: {
            "width": "80%",
            "aspect-ratio": 1
          }
        }, [_c('img', {
          attrs: {
            "src": _vm.$remote(props === null || props === void 0 ? void 0 : (_props$item = props.item) === null || _props$item === void 0 ? void 0 : (_props$item$data = _props$item.data) === null || _props$item$data === void 0 ? void 0 : _props$item$data.img_notification, '/imgs/nophotogeneral.png'),
            "alt": ""
          }
        })]), _c('v-spacer')], 1) : _vm._e(), _c('div', {
          class: props.item.data.img_notification ? 'text__section' : 'text__section pl-2'
        }, [_c('h4', [_vm._v(_vm._s(props.item.data.title))]), _c('p', [_vm._v(_vm._s(props.item.data.body))])]), _c('v-btn', {
          staticClass: "btn__close__notification",
          attrs: {
            "color": "white",
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              props.item.data.visualize(props.item.data.id);
              props.close();
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }